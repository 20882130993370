<template>
  <div>
    <TheHeader />
    <div class="text-center py-4">
      <h1 class="display-4 text-light">
        <span class="text-danger">About</span> PASH.
      </h1>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import TheHeader from "@/components/global/TheNavbar.vue";

export default {
  name: "Home",
  components: {
    TheHeader
  }
};
</script>
